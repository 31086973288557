import type {
  LinksFunction,
  LoaderFunction,
  MetaFunction,
} from "@remix-run/node";
import { json } from "@remix-run/node";
import {
  Links,
  LiveReload,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
} from "@remix-run/react";
import { getUser } from "./session.server";
import { Toaster } from "react-hot-toast";

import tailwindStylesheetUrl from "./styles/tailwind.css";
import siteStylesUrl from "./styles/styles.css";
import quillCss from "quill/dist/quill.snow.css";
import ag1 from "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import ag2 from "ag-grid-community/styles/ag-theme-alpine.css"; // Optional theme CSS
import rdg from "@inovua/reactdatagrid-enterprise/base.css";
import gridCss from "@inovua/reactdatagrid-enterprise/index.css";
import { NextUIProvider } from "@nextui-org/react";
import drawerCss from "react-modern-drawer/dist/index.css";
import reactPdf from "react-pdf/dist/esm/Page/TextLayer.css";
import reactPdfAnnotations from "react-pdf/dist/esm/Page/AnnotationLayer.css";

export const links: LinksFunction = () => {
  return [
    { rel: "stylesheet", href: tailwindStylesheetUrl },
    { rel: "stylesheet", href: drawerCss },
    { rel: "stylesheet", href: quillCss },
    { rel: "stylesheet", href: reactPdf },
    { rel: "stylesheet", href: reactPdfAnnotations },
    { rel: "stylesheet", href: ag1 },
    { rel: "stylesheet", href: ag2 },
    { rel: "stylesheet", href: rdg },
    { rel: "stylesheet", href: gridCss },
    {
      rel: "stylesheet",
      href: "https://api.mapbox.com/mapbox-gl-js/v2.8.1/mapbox-gl.css",
    },
    {
      rel: "stylesheet",
      href: "https://cdn.jsdelivr.net/npm/semantic-ui@2/dist/semantic.min.css",
    },

    { rel: "stylesheet", href: siteStylesUrl },
  ];
};

export const meta: MetaFunction = () => ({
  charset: "utf-8",
  title: "DealMesh",
  viewport: "width=device-width,initial-scale=1",
});

type LoaderData = {
  user: Awaited<ReturnType<typeof getUser>>;
};

export const loader: LoaderFunction = async ({ request }) => {
  return json<LoaderData>({
    user: await getUser(request),
  });
};

export default function App() {
  return (
    <html lang="en" className="h-full">
      <head>
        <Meta />
        <Links />
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-347D8ZVQE0"
        ></script>
      </head>
      <body>
        <div className="h-auto min-h-full bg-slate-100">
          <NextUIProvider>
            <Toaster />
            <Outlet />
            <ScrollRestoration />
            <Scripts />
            <LiveReload />
          </NextUIProvider>
        </div>
      </body>
    </html>
  );
}
